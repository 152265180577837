import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react'
const SchoolInformation = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'School Information - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>School Information</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Information</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12">
                  <h5>Presentation Convent Senior Secondary School, S.P. Mukherji Marg, Delhi</h5>
                  
                  <p className="inner-para">
                 Is a Christian Minority School. It is an unaided English medium school for girls. The school is run by the Society of Presentation of the Blessed Virgin Mary (PBVM) Order under Societies Registration Act, XXI of 1860,(Punjab Amendment) Act 1957 as extended to the Union Territory of Delhi. The school is located in the heart of Delhi.<br/>
                      
                 The School has been granted Permanent Recognition by the Department of Education, NCR Territory of Delhi vide their letter number F.3(7)/79-ZVG/4158, dated 30.11.1979.<br/>
                      
                 The school is located in the heart of Delhi. Facilities for indoor games and outdoor games as well as a separate Dance room and a Music room are available for children to discover and enhance their talents.<br/>
                      
                 It is Affiliated to Central Board of Secondary Education, Delhi Region at Senior Secondary level. The Affiliation number is 2730061. It had been granted provisional affiliation in 1977. The Provisional Affiliation has now been further extended up to 31.03.2018.<br/>
                      
                 The Academic session is from April to March. Summer vacations begin around the third week of May till end of June.</p>
                    
                  <h5>Admission</h5> 
                  <p>The Academic session is from April to March. Summer vacations begin around the third week of May till end of June.<br/>
                  Registration for Pre-Primary class begin as per the government notification and guidelines issued from time to time. Registration for other classes begin around the third week of February.</p>
                    
                  <h5>Subject Taught at Senior Secondary Level</h5>
                  <p>English Core, History, Political Science, Economics, Sociology, Mathematics, Physics, Chemistry, Biology, Business Studies, Accountancy, Computer Science and Multimedia &amp; Web Technology.</p>
                </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                   <h5>Managing Committee Members</h5>
                 </div>
                <div className="col-lg-4">
                  <ul>
                    <li> Sr. Lizzy  Mathew </li>
                    <li> Sr. Chitra Varghese </li>
                    <li> Sr. Regi Joseph </li>
                    <li> Sr. Hema Barretto </li>
                    <li> Sr. Rosamma Thomas </li>
                    <li> Mrs. Sheetal Jaison </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <ul>
                  <li> Mrs. Sherin George </li>
                    <li> Mr. Ashish Ranjan </li>
                    <li> Mr. Arun Sharma </li>
                    <li> Mr. Gaurav Kumar </li>
                    <li> Mr. Himal Akhtar </li>
                    <li> Br. Robert Fernandes </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <ul>
                  <li> Fr. Jose T.J.  </li>
                    <li> Sr. Stella Joseph </li>
                    <li> Mr. Navnit Kumar </li>
                    <li> Mrs. Shilpi Rani </li>
                    
                  </ul>
                </div> 
              </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SchoolInformation
