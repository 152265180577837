import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import {  useEffect } from 'react';
import Footer from'../Component/Footer'
const SchoolCabinetMembers = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Cabinet Members - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li> Cabinet Members</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Cabinet Members</h1>
                    </div>
                </div>
                <div className="row"> 
                <div className="col-lg-3">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/CM-HeadGirl.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>SARAH SHAAN</h4>
                      <h5>Head Girl<br/> <span>12<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/CM-Deputy-Head-Girl.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>BHAVIKA ARORA </h4>
                      <h5>Deputy Head-Girl<br/> <span>11<sup>th</sup>- C </span></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                   <h5>Sports <span>Members</span> </h5>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SM-Captain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>NAVYA JUNEJA</h4>
                      <h5>Captain<br/> <span>12<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SM-ViceCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>ANANYA YADAV</h4>
                      <h5>Vice Captain<br/> <span>11<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SM-MCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>SIYA KAUSHIK</h4>
                      <h5>Middle Captain<br/> <span>8<sup>th</sup>- B </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SM-PrimaryCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>ANUSHREE KOKHAR</h4>
                      <h5>Primary Captain<br/> <span>5<sup>th</sup>- B </span></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                   <h5>Jyoti <span>House</span> </h5>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/jh-Captain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>GAURI MAHESHWARI</h4>
                      <h5>Captain<br/> <span>11<sup>th</sup>- D </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/jh-ViceCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>SHWETA TYAGI</h4>
                      <h5>Vice Captain <br/> <span>10<sup>th</sup>- B </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/jh-MCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>KRISHIKA JOSHI</h4>
                      <h5> Middle School Captain <br/> <span>8<sup>th</sup>- D</span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/jh-PrimaryCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>MISHA NARULA</h4>
                      <h5>Primary School Captain <br/> <span>5<sup>th</sup>- C</span></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                   <h5>Shanti <span>House</span> </h5>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sh-Captain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>CHARU KOHLI</h4>
                      <h5>Captain<br/> <span>11<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sh-ViceCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>MUSKAN</h4>
                      <h5>Vice Captain<br/> <span>10<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sh-MCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>JIYA KUMARI</h4>
                      <h5>Middle School Captain<br/> <span>8<sup>th</sup>- B </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management" >
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sh-PrimaryCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>MEDHA JOSHI</h4>
                      <h5>Primary School Captain<br/> <span>5<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row">
                <div className="col-lg-12">
                   <h5>Neeti <span>House</span> </h5>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/nh-Captain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>KANAK NATH</h4>
                      <h5>Captain<br/> <span>11<sup>th</sup>- C </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/nh-ViceCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>PHOEBE HOIHMUANKIM</h4>
                      <h5>Vice Captain<br/> <span>10<sup>th</sup>- B </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/nh-MCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>GITANJALI ADITYA</h4>
                      <h5>Middle School Captain<br/> <span>8<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/nh-PrimaryCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>FIZA KAUSAR</h4>
                      <h5>Primary School Captain<br/> <span>5<sup>th</sup>- B </span></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                   <h5>Shradha <span>House</span> </h5>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/Shradhah-Captain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>ANGEL ROSE THOMAS</h4>
                      <h5>Captain<br/> <span>11<sup>th</sup>- C </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/Shradhah-ViceCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>DIVYA JAIN</h4>
                      <h5>Vice Captain<br/> <span>10<sup>th</sup>- A </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/Shradhah-MCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>ASHVINI ADHIKARI</h4>
                      <h5>Middle School Captain<br/> <span>8<sup>th</sup>- D </span></h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="management">
                     <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/Shradhah-PrimaryCaptain.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>CHARVI CHANDHOK</h4>
                      <h5>Primary School Captain<br/> <span>5<sup>th</sup>- D </span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SchoolCabinetMembers
