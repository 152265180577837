import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const SDG = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'SDG - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Activity </li>
                <li>SDG</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>SDG</h1>
                    </div>
                </div>
                <p>There is no ‘Plan B’ because we do not have a ‘Planet B’. We have to work and galvanize our action. <br/>
                We at PCS, Delhi are committed towards achieving #Agenda2030 aligned with the vision of our school foundress, Venerable Nano Nagle. The school in Session 2021-22 devoted a great deal of time to educating young learners on values and to creating awareness about global goals. </p>
                  <h5><b>SDG CLUB</b></h5>
                <p>The SDG club constituted in Middle school is involved in co-scholastic activities that seek to create awareness about the sustainable development goals. Students discuss the goals in groups and create posters, slogans, etc. for understanding the goals and thinking about possible ways to achieve them.</p>
                <div className="row sdd-imgs">
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_1.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_2.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_3.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_4.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_5.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                </div>
                
                 <h5><b>VIDEO CAMPAIGN</b></h5>
                <p>The school ran  video campaigns in online mode of teaching learning on various occasions, such as World Water Day, Forest Day, Science Day, etc. These videos were made by students under the guidance of their class teacher and uploaded to the school's YouTube channel.</p>
                <div className="video-sdg">
                <a href="https://youtube.com/playlist?list=PLnHvn6s7kL0SYfRtF8sCnCKKPkO9TGcrP" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/video_icon.png" className="video-img img-fluid" alt="PCS DELHI"/><p>Videos Channel Link</p></a>
                </div>
                 <h5><b>GLOBAL GOALS DAY ASSEMBLY (ONLINE)</b></h5>
                <p>On September 21, 2021, Global Goals Day was commemorated with an assembly produced by students of 11 A with the help of their class teacher and school management. It was broadcast live on MS Teams and conveyed the message that the earth's resources should be preserved for future generations.</p>
                <div className="video-sdg">
                <a href="https://youtu.be/aK2FjHBfvDw" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/video_icon.png" className="video-img img-fluid" alt="PCS DELHI"/><p>Videos Channel Link</p></a>
                </div>
                
                 <h5><b>SDG  AWARENESS CAMPAIGN</b></h5>
                <p>During the academic session, a cohort of teachers was selected as SDG Ambassadors and together with their respective classes, they devised practical and workable solutions for all the 17 goals like compost making, indoor planting, roti bank, research and outreach, etc. </p>
                 <h5><b>ANTI POLYBAG CAMPAIGN</b></h5>
                <p>Keeping in mind the SDG 3: Good Health and well-being, 
                SDG 12: Responsible consumption and production, SDG 14: Life below water and SDG 15: Life on land, Presentation Convent Sr. Sec. School Delhi-6, organized various activities for all the classes to spread awareness on the ill effects of using polybags. Students participated in the campaign with full enthusiasm and zeal.</p>
                <div className="row sdd-imgs">
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_6.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_7.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                </div>
                 <h5><b>GLOBAL GOALS DAY ASSEMBLY (IN CAMPUS)</b></h5>
                <p>With the sole purpose of highlighting the importance of SDGs like Zero Hunger and Good health and wellbeing. Students of class 10 A and B presented the assembly on Global Goals Day on 9 September 2022.  A Street Play titled ‘Sunke Jao Yaaro’ was showcased. The students creatively displayed the 17 sustainable goals along with depicted the measures that can be adopted to fulfil these common goals. </p>
                <div className="row sdd-imgs">
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_8.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_9.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_10.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                </div>
                
                
                 <h5><b>INTERSCHOOL SDG QUIZ COMPETITION</b></h5>
                <p>The school conducted its 1st Inter school SDG competition on 30th August 2022. Eight schools participated in the one-of-a-kind event. Mr. Deepak Dixit, Project Manager at Engineers India Limited graced the occasion as Chief guest, and Ms. Priyanshi Sharma, news reporter and news anchor at NDTV 24*7 as Judge for the day.  The competition consisted of 4 rounds and participants enthusiastically participated in all the rounds. St. Margaret won the rolling trophy and the first runner ups trophy was bagged by PCS Delhi 6. </p>
                <div className="row sdd-imgs">
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_11.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_12.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_13.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_14.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_15.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_16.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/sdg_activity_17.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                </div>
                
                
                
                
                
                 <h5><b>SHAPE IT OUT COMPETITION</b></h5>
                <p>A math competition was held for students of grade 5 on 13 October 2022. They explored the world of shapes where they created different scenes based on SDG 14- Life below water and SDG 15- Life on land using various mathematical shapes. The objective of the activity was to relate Mathematics with real-life scenarios. Students shared their scenes and talked about the importance of saving aquatic animals as well as animals living on land.  </p>
                <div className="row sdd-imgs">
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SHAPE-IT-1.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SHAPE-IT-2.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SHAPE-IT-3.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/SHAPE-IT-4.jpg" className="img-fluid" alt="PCS DELHI"/></div>
                </div>
                
                
                
                 <h5><b>AMBASSADOR OF LOVE PROGRAM</b></h5>
                <p>The students of classes 5B, 8C, and, 8D enthusiastically participated in the Ambassador of Love program, which was a “Bhandara” on 21 October 2022. The young girls wholeheartedly distributed Rajma and rice along with a sweet dish to the poor and destitute near the area of the old Delhi railway station. The learners contributed to the program, by all means, they distributed the food item, refilled the utensils, and, served water to around 350 people who came to take the meals. The students expressed their gratitude towards the school management, teachers, and support staff to give them an opportunity to indulge in the community service program.</p>
                <div className="row sdd-imgs">
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/AMBASSADOR-1.png" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/AMBASSADOR-2.png" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/AMBASSADOR-3.png" className="img-fluid" alt="PCS DELHI"/></div>
                <div className="col-lg-4"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/AMBASSADOR-4.png" className="img-fluid" alt="PCS DELHI"/></div>
                </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SDG
