import React,{ useEffect } from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const EnvironmentalManagement = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Environmental Management - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School</li>
                <li> Environmental Management</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Environmental Management</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12">
                
                <h5>General Info</h5>
                <p>We at <strong>Presentation Convent Senior Secondary School</strong> regard Environmental Protection and Preservation as our prime responsibility.<br/>
                  We shall comply with all applicable Environmental Legislation, Regulations and Directives.<br/>
                  We shall help our students in becoming environmentally knowledgeable, skilled and dedicated citizens who are willing to work individually and collectively towards achieving and maintaining a dynamic equilibrium between the quality of life and the quality of environment.<br/>
                  We shall investigate environmental issues, evolve alternative solutions and work towards sustainable development.<br/>
                  We shall make our school an Eco-friendly Oasis and strive for continual improvement in our environmental performance by suitably setting and periodically reviewing our objectives and targets.<br/>
                  We shall document, implement, maintain and communicate this policy to all the staff and make it available to the public.</p>
                <h5>Our Achievements</h5>
                <p>Environmental Management System aims to bring about an attitudinal change in staff and students alike. All the programmes or schemes under this system have been conceptualized keeping in mind the environmental policy, which is accessible and open to public. Under EMS, creating awareness regarding preservation and protection of the environment forms a significant manifestation. Intra and inter school debate, talks on environmental issues, week long programmes like the earth Watch, Greening Delhi and Van Mahotsava, campaigns like anti-polybags and anti-crackers, ban on use of polybags by students and staff, are some of the activities geared towards creating awareness not only amongst staff and students but also amongst public. A paper-recycling unit in school is operating in full swing. Enzyme composting and pressure composting systems have been suspended due to the takeover of land by Delhi Metro Rail Corporation for the construction of underground tunnel and Metro Station. </p>
                <p>Landscaping of the school to add beauty and greenery and for Eco-climate control is being continuously done. Besides this, a conscious effort is made to meet all legal requirement laid down by the government to have a clean and green environment. Towards this, the school has been declared as a "No Smoking Zone",and an acoustic enclosure A diesel generator set has been put up. PUCC for the school vehicle is taken quarterly and to treat waste water from RO system and rain water, a tank 10 x 10 x 8 feet has been made and the collected water shall be used to irrigate the field. Such a multitude of activities is bound to bring about an environmental change for the good and the students shall be our main beneficiaries. We feature in the Limca Book of Records as the first school to opt for EMS certification.</p>
                <h5>Ban On Smoking</h5>
                <p>In compliance with the Delhi Administration ban on smoking at public places, PCS has decided to confirm to the ban and has declared its premises as a <strong>"NO SMOKING ZONE"</strong>. All the visitors are requested to strictly adhere to it.</p>
              </div>
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default EnvironmentalManagement
