import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const StudySkills = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Effective Study Skills - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>   Academic </li>
                <li>Effective Study Skills</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Effective Study Skills</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <div className="clearfix">
                      <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/StudySkills.png" className="img-fluid col-md-5 float-md-end mb-3" alt=""/>
                      
                      <p><strong>EFFECTIVE STUDY SKILLS </strong>Learning effective time management is the first step towards becoming an effective student. Time management is setting and following of a time table of study in order to organize and prioritise studies in the context of completing other activities. The focus of time management is not to make you a slave, to follow a rigid military like schedule. Rather, it means making intelligent decisions about when it is easiest and most efficient to get your work done and to free you from hasty planning and last minute study. Effective time management is to identify key areas of your study on which to concentrate in order to achieve overall result. Below are some important strategies to remember in managing your time effectively.</p> 
                      
                      <ul>
                      <li><span className="green">Organise yourself :</span> Every day take time to think and plan your time fruitfully - paying attention to the tasks at hand. The type of focus it requires, the style of processing and the strategy of action. Review your plan daily and check whether you have met your targets.</li>
                      <li><span className="green">Make a To-Do-list : </span>Write down the things you have to do, then decide what to do at the moment and what to put off for later.</li>
                      <li><span className="green">Prioritize your tasks :</span> Check whether the task is urgent and important. Arranging your tasks in order of priority assures that you will get the most important things accomplished.</li>
                      <li><span className="green">Set goals :</span> Goals give you direction and a plan to spend your time. Select only manageable amount of tasks. Setting smaller goals allows you to actually accomplish them. Your goals should <strong>“stretch”</strong> but not <strong>“Break”</strong> you as you strive for achievement.</li>
                      <li><span className="green">Make a time table :</span> Check for yourself how much time you need to complete each task and then schedule that amount of time. Be flexible but do not give more than two hours at a stretch to any particular subject. Try to be regular and strict. Your reward will be seen in your meritorious performance.</li>
                      <li><span className="green">Identify your Prime time : </span>Some work better during the day while others during the night. Use your prime time to study.</li>
                      <li><span className="green">Attack your difficult subjects first :</span> Remember when you are fresh, you can process information faster and as a result save time.</li>
                      <li><span className="green">Space your learning : </span>Study in shorter time blocks with short breaks in between. This keeps you from getting fatigued. It is better to study one hour each day than to study seven hours during the weekend.</li>
                      <li><span className="green">Develop Regularity : </span>You will learn more and remember what you learn if you work in regular periods of study.</li>
                      <li><span className="green">Conquer Procrastination :</span>Sometimes studies are not done because we occupy ourselves with other activities. When you are avoiding something, break it into smaller tasks. Remember, the full use of today is the best preparation for tomorrow.</li>
                      <li><span className="green">Display Persistence : </span>Be prepared for a certain amount of discomfort or hardship. But remember, as a student studying is a perfectly normal activity and that it can bring its own rewards and satisfaction. Keep in mind that you have opted to pursue your studies, which will bring their own rewards.</li>
                      <li><span className="green">Reward yourself :</span> When you have a boring task which you prefer not to do, then promise yourself that as soon as you complete it, you will reward yourself with a pleasurable activity that you look forward to. Integrate work with play and relaxation, and you will be healthier, happier, dynamic and creative.</li>
                      <li><span className="green">Impose Deadline :</span> Deadline forces you to plan better. They automatically cause you to think about what has to be done, how long each part will take, when the best or most logical starting time would be. Deadlines get many tasks done and constantly keeps you focussed on your goals.</li>
                      </ul>
                      </div> 
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default StudySkills
