import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect, useState } from 'react'; 
import {getFaculty} from'../Service/Api'
const CelestialMentor = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Celestial Mentor - Presentation Convent School Delhi';  
  }, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 
  console.log(data);

  useEffect(() => {
      const fetchData = async () => {
          try {
              const CatalystofGrowth = await getFaculty();
              const CatalystData = CatalystofGrowth.filter(item => item.category === "Celestial")
              const sortedData = CatalystData.sort((a, b) => a.name.localeCompare(b.name));
              setData(sortedData);
              
          } catch (error) {
              console.log("Data Error", error);
          } finally{
              setLoading(false)
          }
      };
      fetchData();
  }, []);
  const emptyData = [
     { id: 1},
     { id: 2},
     { id: 3}
 ];
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>Celestial Mentor</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Celestial Mentors: Shining in Eternity</h1>
                    </div>
                </div>
                <div className="row">
                {data.length > 0 ? data.map((item,index) => (
                  <div className="col-xxl-3 col-lg-4 col-md-4" key={index}>
              <div className="management">
                {item.attachments.length>0 ? (<img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid" alt=""/> ) : 
                (<img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/defaultImage.jpg" className="img-fluid"/>)
              }
              
                <div className="management-detils">
                  <h4> {item.name} </h4>
                  
                </div>
              </div>
            </div>  
            )) : emptyData.map((data) => (
                 <div className="col-xxl-3 col-lg-4 col-md-4" key={data.id}>
              <div className="management">
                <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/defaultImage.jpg" className="img-fluid"/>
                <div className="management-detils">
                  <h4> Name </h4>
                   
                </div>
              </div>
            </div>   
                ))}
              </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default CelestialMentor
