
import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const SchoolHistory = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'School History - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>School History</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School History</h1>
                    </div>
                </div>
                <div className="row">
                        
<div className="col-lg-12">
<img alt="PCS Delhi" className="img-fluid rightimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-14-01-1939.jpg"/>
 <p> <strong>The incredible story begins way back in 1924,</strong> when Fr. Luke O.F.M. Cap started a school in a tent with just 13 students, in the diocese of Delhi-Simla. This school was named as St. Teresa’s High School and it catered to the children of the employees of the Northern Railways and local children who were mostly British.<br/>          
<strong>In 1939, Bishop Sylvester Mulligan O.F.M. Cap (diocese of Delhi-Simla) invited the Presentation Sisters from Rawalpindi</strong>, now in Pakistan, to take over the management of the school. The three pioneering sisters, Sr. Gabriel Monks, Sr. Michael Murray and Sr. Augustine McGurke accepted the call of the Divine to fulfill Nano Nagle’s vision and mission of education, and to keep alive her dream and legacy and arrived on 8 December in the same year. Taking courage and strength from Nano Nagle, a woman of indomitable spirit and dedication, Presentation Sisters joined their endeavour. <br/>                 
<strong> This led to the propitious entry of the Presentation Sisters in Delhi.</strong> They were later joined by Sr. Rita, Sr. Dominic and Sr. Jerome. Under the able guidance of Sr. Gabriel Monks, the new management took over the running of the school from 14 January, 1939.</p>
</div>


<div className="col-lg-12">
<img alt="PCS Delhi" className="img-fluid leftimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-11945.jpg"/>
<p><strong>It was a period of great turmoil and struggle for the sisters as World War II was raging</strong>, so movement and change was the order of the day. As early as 1940, Sr. Michael had a keen desire to do more than classroom teaching. She opened a class for adult women, teaching shorthand and typing to Indian ladies, while Sr. Gabriel took great interest in caring for the Anglo-Indian children and the people.  <br/>         
<strong>Sr. Gabriel Monks was the first principal of the school until 1945,</strong> a lady of great versatility and foresight charged with compassion and dedication. She had a flair for music and was a wonderful musician with a great sense of humour. In 1945 the school had the second principal, Sr. Mary Sacred Heart, who served the school for six years.</p>             
</div>      
 
<div className="col-lg-12">
 <img alt="PCS Delhi" className="img-fluid rightimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-1947.jpg"/>
 <p><strong>The year 1947 saw the painful partition of India and Pakistan,</strong> and the sisters witnessed bloodshed in the streets and felt threatened. A group of rioters entered the school premises and ordered the school tobe closed but the sisters with their serene demeanour dealt with them calmly. The school remained closed for two weeks. Despite limited space and paucity of funds, the zeal of the sisters never diminished. Another milestone was the establishment of St. Francis Xavier’s school in 1950, to cater to the Hindi-speaking children. In July 1963, Sr. Berchmans took over as the fifth Principal of the school. <br/>

  
<strong>Under her leadership in 1964, the school celebrated 25 years of the Presentation presence in Delhi-6</strong>, a landmark in the history of the school. The Blessing of the Divine transcended all barriers and fostered goodwill among the community, thus inspiring them to continue in the foot- steps of their foundress Nano Nagle. The Silver Jubilee celebration was graced by the presence of Late Mrs Indira Gandhi, former Prime Minister of India. Sr. Veronica Mannion was the next Principal, from 1969 to 1974. A soft-spoken lady with a pleasant personality, she worked for the betterment of the institution with her friendly and personal touch of humility. Sr. Bonaventure Morgan, a truly dedicated person, was appointed the seventh Principal in the summer of 1974. She continued to serve the institution for twelve successful years until September 1986.</p>       
</div>    
  
<div className="col-lg-12">

<img alt="PCS Delhi" className="img-fluid leftimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-1969-74.jpg"/>
<p><strong>The Silver Jubilee celebration was graced by the presence of Late Mrs Indira Gandhi,</strong> former Prime Minister of India. Sr. Veronica Mannion was the next Principal, from 1969 to 1974. A soft-spoken lady with a pleasant personality, she worked for the betterment of the institution with her friendly and personal touch of humility. Sr. Bonaventure Morgan, a truly dedicated person, was appointed the seventh Principal in the summer of 1974. She continued to serve the institution for twelve successful years until September 1986.<br/>
<strong>In 1976, the school Managing Committee decided to change the board of examination</strong> from Indian School Certificate of Examination (ICSE) to Central Board of Secondary Education (CBSE). The year 1983 was eventful in many ways. Sr. Anselm was appointed the first Indian Superior of the community—her foresight, planning, diligence and sense of mission to the less privileged members of society was remarkable. On the occasion of the bicentenary of the death of the Foundress Nano Nagle, the school put up a par excellence ballet Deepshikha on 12-13 April 1984, at FICCI Auditorium, New Delhi. It delineated the life of Nano Nagle, her struggles, her strength and courage in the face of ordeals.  
</p>    
</div>     
   
<div className="col-lg-12">
<img alt="PCS Delhi" className="img-fluid rightimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-1987.jpg"/>
<p><strong>Sr. Marie, who believed in caring for the marginalised, took over as the principal of St. Francis Xavier School</strong> from Sr. Anselm and she continued until the amalgamation of the two schools. Then came the moment of great bliss on 1 April 1987, when St. Teresa’s High School and St. Francis Xavier’s school merged as one under the banner of Presentation Convent Senior Secondary School, Delhi-6, affiliated to CBSE. The credit goes to Sr. Anselm and Sr. Kathrina who with their grit and determination strove to achieve this milestone. The ratification came into being during the tenure of the first Indian Principal, Sr. Roselima D’Costa. Being a very humble and warm person, she justified her significant role by tying loose ends and holding the chords firm. She has truly been blessed with ‘green fingers’ as she not only nurtured lives but also the plants in the school campus.</p>
</div> 
   
<div className="col-lg-12">
<img alt="PCS Delhi" className="img-fluid leftimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-1990.jpg"/>
<p><strong>As we move ahead on the sands of Golden Years, Sr. Gemma Pires took over as the ninth principal of the school in 1990.</strong> She is true to her name—a ‘gem’ of a person, who with her kind and considerate temperament was able to carry the torch forward successfully. The story continued with the same enthusiasm, when Sr. Anice George took over as the principal from 1993 to 1998. A cheerful person always ready to venture out with new ideas, she founded the Inter-school Nano Nagle English debate which addresses social issues which still continues. Sr. Phyllis Pullickel took over as the next Principal in 1998. Under her dynamic leadership, the school held a grand two-day event, ‘Jeevan Niranthar’ on 18-19 December 1999, to mark the finale of its Diamond Jubilee Celebration, which was organised in collaboration with Anant Association for Nascent Art and Natural Theatre. The occasion was graced by the then Chief Minister, Mrs. Shiela Dikshit; Union Information &amp; Broadcasting Minister, Shri Arun Jaitley; Member of Parliament (Lok Sabha), Shri Vijay Goel; and Health Minister, Delhi Government, Dr. A.K. Walia.</p>
</div> 

<div className="col-lg-12">
<img alt="PCS Delhi" className="img-fluid rightimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-1997.jpg"/>
  <p><strong>The highlight of the event was based on contemporary issues such as sustainable development,</strong> gender and social inequality, and also the challenge to traditional value system in a tech-savvy world. In 2001, Presentation Convent Senior Secondary was the first school to be ISO certified for Quality Management System (QMS) and Environmental Management System (EMS). Indeed a proud moment for the school! QMS policy aims at achieving excellence while imparting holistic education to the girl child. EMS promotes sustainable development, growth and conservation of the environment. The school has a precedence of being declared the lead Eco-club of NCR, Delhi, since 1997. Furthermore, in 2008, it added another feather to its cap when it was certified as Green School by CSE (Centre for Science and Environment, New Delhi). Sr. Rosamma Thomas had taken the baton from Sr. Phyllis in 2001, and served the institution until 2008. Dedicated and meticulous to the core, her professionalism is indeed remarkable!</p>
</div>       

  
<div className="col-lg-12">
<img alt="PCS Delhi" className="img-fluid leftimg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/history-2009.jpg"/>
<p><strong>Sr. Elsie Thomas was appointed Principal from July 2008 until the summer of May 2009.</strong> Her soft-spoken and mellowed demeanour encouraged the staff members to give their best and made teaching a pleasant experience. In July 2009, the reign of the school was entrusted in the able hands of Sr. Regi Joseph and she continues to serve. She imparts a Midas touch to everything undertaken by the school. The year 2014 witnessed the glorious moment in the history of the school when it celebrated the Platinum Jubilee of Presentation Presence, thus continuing their commitment to the exhaustive development of each child within the ever-changing multicultural context of India and pursuing a culture of excellence.</p>
</div>  
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SchoolHistory
