import React  from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react'
const AdmissionDetails = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Admission Details - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Admission  </li>
                <li>Admission Details</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Admission Details</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                             <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cm.jpg" className="img-fluid mx-auto d-block"/> 
                      </div> 
                 </div> 
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default AdmissionDetails
