import React,{ useEffect, useState } from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {getEHandBook} from'../Service/Api'
const EHandbook = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'E-Handbook - Presentation Convent School Delhi';  
      }, []);
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(true); 
   console.log(data);

   useEffect(() => {
       const fetchData = async () => {
           try {
               const handbookdata1 = await getEHandBook();
               const handbookdata = handbookdata1.filter(item => item.category === "Ehandbook")
               setData(handbookdata);
               
           } catch (error) {
               console.log("Data Error", error);
           } finally{
               setLoading(false)
           }
       };
       fetchData();
   }, []);
   const emptyData = [
      { id: 1},
      { id: 2},
      { id:3},
      { id:4}

  ];
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Gallery  </li>
                <li>E-Handbook</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>E-Handbook</h1>
                    </div>
                </div>
                <div className="row">
                {data.length > 0 ? data.map((item,index) => (
                    <div className="col-lg-3 grow" key={index}>
                    <div className="Magazine"> 
                       <a href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} target="_blank">
                          <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/Magazine.jpg" className="img-fluid"/>
                          <p className="text-center truncate1"> {item.title}</p>
                        </a> 
                     </div>
                   </div>  
                   )) : emptyData.map((item) => (
                     <div className="col-lg-3 grow" key={item.id}>
                     <div className="Magazine"> 
                        <a href="#" target="_blank">
                           <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/Magazine.jpg" className="img-fluid"/>
                           <p className="text-center truncate1"> Title</p>
                         </a> 
                      </div>
                    </div>  
                    ))}
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default EHandbook
