import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react'
const Cultural = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Cultural - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Activity  </li>
                <li>Cultural</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container infra">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Cultural</h1>
                    </div>
                </div>
                <h5>Art and Craft</h5>
                <p><strong>Art and Craft: "Creativity does not wait for the other person to come first." </strong>Creativity pervades all human activities – academic, recreational or otherwise. Art is a means to mould it. It is the most raw and original form to express and enjoy the various colours of life. Unique as these exquisite colours is the Art Activity Club of our school. The Club, formulated in 2001, holds continual and successful activities. By discovering the hidden talents of the students and boosting their self confidence, the Club creates new methods to bring out the innovative, creative, enthusiastic and active participation of the students…… Flower making, pot decoration, wall hangings, jaipuri birds, art out of waste, interior decoration being some of them. The students creatively design school bulletin boards. The Club not only enhances and develops the skills of the students but also exhibits their achievements and talents during PTM, exhibitions and visits by foreign dignitaries. The hard earned appreciation makes them swell with pride, joy and happiness. Time and again, competitions are held to keep alive the spirit of a true artist.</p>
                <div className="row">
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural1.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> 
                </div>
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural2.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> 
                </div>
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural3.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> 
                </div>
                </div>
                <h5>Dance</h5>
                <p> Dance at Presentation Convent Sr. Sec. School inculcates the aspects of commanding a fascinating world of imagination. Fusion of classical and folk dances jot downs the various chapters and stages of our history and which thereby, motivates students to know more about the diversified Indian culture. The school also organizes stage performances for social causes such as for the inmates of SOS – Bawana, Tihar jail, old age homes, etc. The dance fest which is held every year is highly appreciated by all.</p>
                <div className="row">
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural4.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural4.jpg" className=" img-fluid" alt="PCS DELHI" /></a> 
                </div>
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural5.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural5.jpg" className=" img-fluid" alt="PCS DELHI" /></a> 
                </div>
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural6.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural6.jpg" className=" img-fluid" alt="PCS DELHI" /></a> 
                </div>
                </div>
                <h5>Music (Instrumental &amp; Vocal)</h5>
                <p>The school choir is one of the best among Delhi schools. This group has been participating in various competitions and has bought laurels for the school. Every year, they are invited to Rashtrapati Bhavan to sing carols before the President of India and other dignitaries. The choir groups is at three levels namely, Senior, Junior and Sub-Junior. The school is also well equipped with many different types of musical instruments. Regular practices are held during summer vacations. The yearly music fest is mindblowing and gives the nightingales of the school a platform to showcase their music talent.</p>
                <h5>Rangoli</h5>
                <p>Rangoli and Alpana are the two traditional decoration art taught in the school. Students are given an awareness of the different floor decoration arts practiced in different states of India. They learn to select designs according to the mood and theme of the occasion for which it is done. Students also learn to combine colours and show projection in their artwork. Through this art, the students get a chance to show their creativity.</p>
                <p>Students are encouraged to use inexpensive materials like dyed sawdust, zinc oxide and gheru to make the designs. The use of flower petals and food material is discouraged. Diyas and accessories are used to give a final and festive look.</p>
                <p><strong>This colourful art</strong> is displayed by students on all festive occasions and to welcome special guests on special occasions.</p>
                <div className="row">
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural7.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural7.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
                </div>
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural8.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural8.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
                </div>
                <div className="col-lg-4">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural9.jpg"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cultural9.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
                </div>
                </div>
                <h5>School Band</h5>
                <p>The school band has 35 students. They play at all school functions. It is highly appreciated by the viewers, which comprise of parents and dignitaries. Regular practices are held after school hours and during summer vacations.</p>
                <p><strong>Trips and excursions:</strong> As part of our awareness programme for students, educational trips and excursions are organized every year. These trips are organized so that the students learn to:
                </p>
                <ul>
                <li>Inculcate the spirit of adventure and enthusiasm.</li>
                <li>Inculcate the habit of self-dependence, co-operation, confidence and responsibility.</li>
                <li>Get a chance to explore, enjoy, admire and take care of our surroundings and thereby enhance their horizon and knowledge.</li>
                <li>Get a chance to know and learn about different cultures, languages, living and food habits of people in different states.</li>
                </ul>
                <p> <strong>Eco tours</strong> are organized to bring students closer to nature and learn to take care of the environment. This also gives them a chance to get away from the hustle and bustle of life as well as from the polluted environment. During these eco-tours, various games and activities related to enhancing their knowledge and love for environment are organized.</p>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default Cultural
