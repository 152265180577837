import { useEffect } from "react";
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const ParentGuidline = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Parent Guidline - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Quick Links  </li>
                <li>Parent Guidline</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Parent Guidline</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <h5><b>CampusCare®  Application Guidelines &amp; Instructions </b></h5>
                      <p className="guideheading"><strong>How to LOG IN Parent Portal?</strong></p>
                      <p className="details"> 1. Open Chrome<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/google.png" alt="School ERP Software, Mobile App, School Management Software"/> OR Mozilla <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/mzilla.png" alt="School ERP Software, Mobile App, School Management Software"/> or Internet <img alt="School ERP Software, Mobile App, School Management Software" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/internet.png"/> </p>
                      <p className="details">
                          2. Write the school URL <strong><a target="_blank" href="https://www.pcscampuscare.org/">"pcscampuscare.org"</a></strong> in address bar.
                          (you will be routed to the Log In credential page).
                      </p>
                      <p className="details">
                          3. Then, enter the USER ID and Click on  "Next button", Then enter
                          PASSWORD and Click on Sign in  button.
                      </p>
                      <p className="details">
                          Once logged in, you are welcomed with various information
                          relating to assignments, news, circulars, date sheet, fee details etc. that
                          are self explanatory. Wish you to go through every links that are
                          provided and explore more.
                      </p>
                      <div className="clr10"></div>
                      <p className="guideheading"><strong>How to RESET PASSWORD in Parent Portal? </strong></p>
                      <p className="details"> 1. Open Chrome <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/google.png" alt="School ERP Software, Mobile App, School Management Software"/> OR Mozilla <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/mzilla.png" alt="School ERP Software, Mobile App, School Management Software"/> or Internet <img alt="School ERP Software, Mobile App, School Management Software" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/internet.png"/> </p>
                      <p className="details">
                          2. Write the school URL <strong><a target="_blank" href="https://www.pcscampuscare.org/">"pcscampuscare.org"</a></strong> in address bar.
                          (you will be routed to the Log In credential page).
                      </p>
                      <p className="details">3. Then, click on “Forgot User ID or Password?” text given below User ID field.</p>
                      <p className="details">4. Select the User Type (For which you want a password) then Enter Registered Mobile No. and click on “Reset” Button.</p>
                      <p className="details">5. You will get an SMS on your registered mobile no. along with login credentials. </p>
                      <div className="clr10"></div>
                      <div>
                          <p className="guideheading"><strong> How to Install &amp; operate MOBILE APP? </strong></p>
                          <p className="details">1. Go to <img alt="School ERP Software, Mobile App, School Management Software" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/playstore.png"/> Store / <img alt="School ERP Software, Mobile App, School Management Software" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/apple.png"/> and Search "CampusCare®". </p>
                          <p className="details"> 2. Install and open the <a href="https://www.entab.in/mobile-apps.html"><b>Mobile App</b></a>.</p>
                          <p className="details">3. Enter school web portal URL: <strong><a target="_blank" href="https://www.pcscampuscare.org/">"pcscampuscare.org"</a></strong> in link bar      &amp; verify. </p>
                          <p>4. After verifying, you will get an option for User ID &amp; Password.</p>
                          <p>Once logged in, you are welcomed with various information relating to assignments, news. circulars, date sheet, fee details etc that are self Explanatory. Wish you to go through every links that are provided and  explore more. </p>
                          <p className="details"><strong>NOTE* :</strong> Kindly update your <a href="https://www.entab.in/mobile-apps.html"><b>Mobile Apps</b></a> time to time to avoid difficulties during usage.</p>
                      </div>
                      <div className="clr10"></div>
                      <div className="assistance">
                          <h5 className="guideheading">Please call or write for assistance:</h5>
                          <p className="details"><strong> <a href="https://www.entab.in/contact-us.html">PARENT HELP DESK</a></strong> ( 8 AM to 8:00 PM ) - <strong>Tele No.</strong> 011- 43193333-Ext : 5,<strong> E-Mail :</strong> <strong><a href="mailto:parentdesk@entab.in" className="yellow-link"> parentdesk@entab.in</a></strong></p>
                      </div>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default ParentGuidline
