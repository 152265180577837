import React, { useEffect } from "react";
import Header from '../Component/Header'
import Slider from '../Component/Slider'
import { Link } from 'react-router-dom'
import News from'../Component/News'
import Campuscare from'../Component/Campuscare'
import Events from'../Component/Events'
import TopperXII from'../Component/TopperXII'
import TopperX from'../Component/TopperX'
import Notification from'../Component/Notification'
import OnlineReg from'../Component/OnlineReg'
import Birthday from'../Component/Birthday'
import Gallery from'../Component/Gallery'
import Message from'../Component/Message'
import Footer from'../Component/Footer'
const Home = () => {
   useEffect(() => {
      // Set the page title
      document.title = 'Home - Presentation Convent School Delhi';  
    }, []);
  return (
     <>
      <Notification/>
       <Campuscare/>
       <OnlineReg/>
        <Header/>
        <div className="mainhomeslider">
            
            <Slider/>
            <div className="midsection">
            <div className="midsectiondiv fst">
               <div className="midsectionbox">
                    <div className="midsectionboximg">
                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/motto.gif" className="img-fluid" alt="Pcs Delhi"/>
                    </div>
                    <div className="midsectionboxdesc">
                         <div className="midsectionboxtitle">
                              <h6>MOTTO</h6>
                         </div>
                         <div className="midsectionboxpara">
                            <p><b>The motto of the school is “VIRTUE” and “LABOUR”. </b> The students are expected to strive for these and to practice justice, charity, honesty, truthfulness, generosity, loyalty and gratitude. They must work hard in school and at home and realise the dignity of labour.  </p>
                         </div>
                    </div>
               </div>
            </div>
           
            <div className="midsectiondiv scd">
               <div className="midsectionbox">
               <div className="midsectionboximg">
                  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/objective.gif" className="img-fluid" alt="Pcs Delhi"/>
                </div>
                    <div className="midsectionboxdesc"> 
                    
                         <div className="midsectionboxpara">
                            <ul>
                            <li> To Provide qualitative teaching-learning process.  </li>
                            <li> To create a just society. </li>
                            <li> To promote human values and character formation. </li>
                            <li> To work towards empowerment of girls. </li>
                            <li> To work towards environmental protection and conservation. </li>
                            </ul>
                         </div>
                         <div className="midsectionboxtitle">
                         <h6> OBJECTIVES</h6>
                     </div>
                    </div> 
               </div>
            </div>
        </div>
            </div>  
         
         <div className="homeabout">
            <div className="container-fluid fulwidth">
             <div className="row">
                 <div className="col-lg-6">
                   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/about.png" className="img-fluid" alt="Pcs Delhi"/>
                 </div>
                 <div className="col-lg-6">
                     <div className="titleheading">
                          <h1><span>presentation convent sr. sec. school</span> ABOUT US</h1>
                     </div>
                      <div className="homeaboutdesc">
                          <p>Is a Christian Minority School. It is an unaided English medium school for girls. The school is run by the Society of Presentation of the Blessed Virgin Mary (PBVM) Order under Societies Registration Act, XXI of 1860,(Punjab Amendment) Act 1957 as extended to the Union Territory of Delhi. The school is located in the heart of Delhi.
                      The School has been granted Permanent Recognition by the Department of Education, NCR Territory of Delhi vide their letter number F.3(7)/79-ZVG/4158, dated 30.11.1979.
                      The school is located in the heart of Delhi. Facilities for indoor games and outdoor games as well as a separate Dance room and a Music room are available for children to discover and enhance their talents. </p>
                         <p>It is Affiliated to Central Board of Secondary Education, Delhi Region at Senior Secondary level. The Affiliation number is 2730061. It had been granted provisional affiliation in 1977. The Provisional Affiliation has now been further extended up to 31.03.2018.</p>
                      </div>
                      <div className="mainbtn">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/knowmorebtn.png" className="img-fluid" alt="Pcs Delhi"/>
                      </div>
                      <div className="schoolsong">
                          <h6>our school song</h6> 
                          <audio controls><source src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/audio.mp3" type="audio/mpeg"/></audio>
                      </div>
                 </div>
              </div>
            </div>
         </div>
         <div className="homenews">
         <div className="container-fluid fulwidth">
             <div className="row">
             <div className="col-xl-4 col-lg-6">
               <div className="maintitle">
                     <div>
                        <p>Notices &</p>
                        <h1>NEWS   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/line.gif" className="img-fluid" alt="Pcs Delhi"/></h1>
                     </div>
                     <Link to="/News"><i className="bi bi-chevron-double-right"></i></Link>
                     <div className="clr">
                       </div>
               </div>
               <News/>
               </div>
               <div className="col-xl-8 col-lg-6 eventpd">
                 <div className="maintitle eventgif">
                       <div>
                       <p>Events &  </p>
                       <h1>  ACTIVITES <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/event.gif" className="img-fluid" alt="Pcs Delhi"/></h1>
                       </div>
                       <Link to="/events"><i className="bi bi-chevron-double-right"></i></Link>
                       <div className="clr">
                       </div>
                 </div>
                   <Events/>
               </div>
             </div>
         </div>
       </div>
       <div className="homemsgsec">
       <div className="container-fluid fulwidth">
           <div className="row">
                <div className="col-lg-12">
                <div className="maintitle eventgif">
                    <div>
                       <p>principal </p>
                       <h1>   MESSAGE<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/msg.gif" className="img-fluid" alt="Pcs Delhi"/></h1>
                       </div>
                       
                       </div>

               <Message/>
               <div className="mainbtn">
                 <Link to="/PrincipalMessage"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/knowmorebtn.png" className="img-fluid" alt="Pcs Delhi"/></Link>
           </div>
                    </div>
            </div>
    </div>
</div>
       <div className="topperandbirth">
           <div className="container-fluid fulwidth">
               <div className="row">
                    <div className="col-lg-4">
                    <div className="maintitle eventgif">
                    <div>
                           <p>birthday </p>
                           <h1>  SECTION<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/birth.gif" className="img-fluid" alt="Pcs Delhi"/></h1>
                           </div>
                           
                           </div>
                     
                         <div className="birthdaysec">
                           <div className="topperribbon"> 
                             <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/birthdayribbon.png" className="img-fluid"/>   
                           </div>  
                                <Birthday/>
                           <div className="topperribbonbottom"> 
                              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/birthdayribbonbottom.png" className="img-fluid"/>   
                           </div>
                         </div>
                    </div>
                    <div className="col-lg-8"> 
                          <div className="toppersecbg">
                          <div className="maintitle eventgif">
                     <div>
                           <p>Toppers </p>
                           <h1>  SECTION<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/toopers.gif" className="img-fluid" alt="Pcs Delhi"/></h1>
                           </div>
                           
                           </div>
                          <div className="topperribbon"> 
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/classicn.png" className="img-fluid"/>   
                      </div>           
                              <div className="row">
                              <div className="col-lg-11">
                                 <TopperXII/>
                              </div>
                              <div className="col-lg-1 topperbtn maintitle">
                                 <Link to="/TopperXII"> <i className="bi bi-chevron-double-right"></i> </Link>
                              </div>
                              </div>
                              
                              <div className="topperribbon">
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/classicn10.png" className="img-fluid"/>
                              </div>
                                       
                              <div className="row">
                              <div className="col-lg-11">
                                 <TopperX/>
                              </div>
                              <div className="col-lg-1 topperbtn maintitle">
                                 <Link to="/TopperX"> <i className="bi bi-chevron-double-right"></i> </Link>
                              </div>
                              </div>
                          </div>
                     </div>
                </div>
             </div>
        </div>  
        <div className="homegallery">
        <div className="container-fluid fulwidth">
           <div className="row">
                <div className="col-lg-12">
                <div className="maintitle eventgif">
                <div>
                      <p> ACTIVITES </p>
                      <h1>   & CELEBRATION<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/gal.gif" className="img-fluid" alt="Pcs Delhi"/></h1>
                      </div>
                      <Link to="/PhotoGallery"><i className="bi bi-chevron-double-right"></i></Link>
                       <div className="clr">
                       </div>
                      </div>
                     <Gallery/>
                     
                </div>
           </div>
         </div>
      </div>
      <Footer/>
     </>
  );
};

export default Home
