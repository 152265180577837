import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const SMCMembers = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'SMC Members - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>SMC Members</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>SMC Members</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12">
                <div className="table-responsive maintable">
                  <table className="table">
                    <tbody>
                        <tr className="tablebg">
                            <td colspan="3">PRESENTATION DELHI</td>
                        </tr>
                        <tr>
                             
                            <td>Sr. Lizzy  Mathew</td>
                            <td>President/Chairperson</td>
                        </tr>
                        <tr>
                            
                            <td>Sr. Chitra Varghese</td>
                            <td>Vice President/Vice Chair Person/Manager</td>
                        </tr>
                        <tr>
                           
                            <td>Sr. Regi Joseph</td>
                            <td>Secretary/Principal</td>
                        </tr>
                        <tr>
                            
                            <td>Sr Hema Baretto</td>
                            <td>Treasurer/Administrator</td>
                        </tr>
                        <tr>
                           
                            <td>Sr. Rosamma Thomas</td>
                            <td>Vice Principal</td>
                        </tr> 
                       <tr className="tablebg">
                            <td colspan="3">REPRESENTATIVES OF TEACHERS</td>
                        </tr>
                        <tr>
                           
                            <td>Mrs. Sheetal Jaison</td>
                            <td>PGT History</td>
                        </tr>
                        <tr>
                            
                            <td>Mrs. Sherin George</td>
                            <td>PRT</td>
                        </tr>
                       <tr className="tablebg">
                            <td colspan="3">REPRESENTATIVES OF PARENTS</td>                    
                        </tr>
                        <tr>
                            
                            <td>Mr. Ashish Ranjan</td>
                            <td>Professor</td>
                        </tr>
                        <tr>
                           
                            <td>Mr. Arun Sharma</td>
                            <td>Environmentalist</td>
                        </tr>
                        <tr className="tablebg">
                            <td colspan="3">REPRESENTATIVES OF MANAGEMENT</td>
                        </tr>
                        <tr>
                            
                            <td>Mr. Gaurav Kumar</td>
                            <td>Advocate</td>
                        </tr>
                        <tr>
                            
                            <td>Mr. Himal Akhtar</td>
                            <td>Advocate</td>
                        </tr>
                        <tr className="tablebg">
                            <td colspan="3">EDUCATIONISTS</td>
                        </tr>
                        <tr>
                            
                            <td>Br. Robert Fernandez</td>
                            <td>Principal, St. Columba’s School</td>
                        </tr>
                        <tr>
                            
                            <td>Fr. Jose T.J.</td>
                            <td>Principal,  St. Xavier’s School</td>
                        </tr>
                        <tr>
                           
                            <td>Sr. Stella Joseph</td>
                            <td>Principal, Mater Dei School</td>
                        </tr>
                       <tr className="tablebg">
                            <td colspan="3">GOVERNMENT NOMINEES</td>
                        </tr>
                        <tr>
                            
                            <td>Mr. Navnit Kumar</td>
                            <td>HoS, Govt. Model School</td>
                        </tr>
                        <tr>
                           
                            <td>Mrs. Shilpi Rani</td>
                            <td>Principal, Jesus Grace Modern School</td>
                        </tr>
                    </tbody>
                </table> 
              </div>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SMCMembers
