import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react';
const Vacancy = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Vacancy - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>Vacancy</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Vacancy</h1>
                    </div>
                </div>
                 <div className="row">
                    <div className="col-lg-7 tcsec"> 
                        <p><strong>PRT</strong> for English and EVS </p> 
                        <p><strong>Graduation with </strong> B.Ed/B.El.Ed. </p> 
                    </div>
                    <div className="col-lg-5">
                            <img alt="PCS Delhi" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/Vacancy.jpg" className="img-fluid"/> 
                        </div>
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default Vacancy
