import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react'
const Clubs = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Clubs - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Activities  </li>
                <li>Clubs</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Clubs </h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <div className="verticaltabs">
                      <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                         <button className="nav-link active" id="v-pills-eclat-tab" data-bs-toggle="pill" data-bs-target="#v-pills-eclat" type="button" role="tab" aria-controls="v-pills-eclat" aria-selected="true">ECLAT</button>
                         <button className="nav-link" id="v-pills-eco-tab" data-bs-toggle="pill" data-bs-target="#v-pills-eco" type="button" role="tab" aria-controls="v-pills-eco" aria-selected="true">Eco Club</button>
                         <button className="nav-link" id="v-pills-lang-tab" data-bs-toggle="pill" data-bs-target="#v-pills-lang" type="button" role="tab" aria-controls="v-pills-lang" aria-selected="true">Languages</button>
                         <button className="nav-link" id="v-pills-health-tab" data-bs-toggle="pill" data-bs-target="#v-pills-health" type="button" role="tab" aria-controls="v-pills-health" aria-selected="true">Health Club</button>
                         <button className="nav-link" id="v-pills-heri-tab" data-bs-toggle="pill" data-bs-target="#v-pills-heri" type="button" role="tab" aria-controls="v-pills-heri" aria-selected="true">Heritage Club</button>
                         <button className="nav-link" id="v-pills-child-tab" data-bs-toggle="pill" data-bs-target="#v-pills-child" type="button" role="tab" aria-controls="v-pills-child" aria-selected="true">Child Rights Club</button>
                         <button className="nav-link" id="v-pills-human-tab" data-bs-toggle="pill" data-bs-target="#v-pills-human" type="button" role="tab" aria-controls="v-pills-human" aria-selected="true">Human Rights Club</button>
                         <button className="nav-link" id="v-pills-cadet-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cadet" type="button" role="tab" aria-controls="v-pills-cadet" aria-selected="true">National Cadet Corps (N.C.C.)</button>
                      </div>
                      <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-eclat" role="tabpanel" aria-labelledby="v-pills-eclat-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>ECLAT</h3>
                   <p> The Economics and Commerce Luminaries Active Team (ECLAT) was formed with the aim of conducting activities to enhance the students' talents and promote awareness regarding commerce related issues. Such activities boost the students' confidence and build their skills. It acts as a force in building up team spirit. It enables them to enjoy and learn the various facets of business world. It constitutes of various competitions such as Versatile, Pass the Buck, Exhibitions, Symposiums and other inter and intra school activities.</p>
                   </div>
                   </div>
                   </div>
                    <div className="tab-pane fade" id="v-pills-eco" role="tabpanel" aria-labelledby="v-pills-eco-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>Eco Club</h3>
                    <p><strong>Creating environmental awareness</strong> has always been one of the major focal points of the school. That is why our school Eco-club is the "Lead Eco-Club" for Central Delhi by the Department of Environment, NCT of Delhi. The Indian Institute of Ecology and Environment and the Global World Management Congress awarded the Eco-friendly School of the Year Award. Our Eco-club members conduct the following activities every year. </p>
                                 <ul className="ullist">
                                   <li><span className="green">Electronic Waste Disposal:</span>The latest initiative taken is by installing Electronic waste disposal bin in the school to sensitize the students about the harmful impact of toxic chemicals present in the waste.</li>
                                   <li><span className="green">Plantation: </span>Saplings are planted during Van Mahotsav week. The saplings are distributed to children and teachers on Children's Day and Teachers' Day respectively.</li>
                                   <li><span className="green">Teacher Training Programme: </span>Teachers from schools attached to our school (Nodal centre) attend a workshop conducted within our premises. <br/>
                                     - Teachers from our school attend workshops/seminars organized by various environmental agencies like: TERI, DEEKSHA, CSE, Department of Environment (NCT Delhi), IES, etc.</li>
                                   <li><span className="green">Zero Garbage:</span>Our school aims at creating a ZERO garbage zone. Dustbins of various colours for segregating garbage at source have been placed at various points. Green coloured dustbin is for Paper and Blue coloured dustbin is for Non-Biodegradable waste and The non-biodegradable waste is picked up by the rag pickers and we do not throw anything in the Municipal Corporation of Delhi made dust pond.</li>
                                   <li>Paper Recycling: A paper-recycling unit has been functioning in our school premises for the since 2000. It has a capacity of 10 kgs. The waste paper from the green dustbins is recycled and various stationery items like files, folders, envelopes, writing pads, school letterheads, scribbling pads, bags and certificates are made from the recycled paper.</li>
                                   <li><span className="green">Rakhi Tying:</span> Students are encouraged to tie Rakhi on trees in order to sensitize and have them and develop a bond with the trees.</li>
                                   <li><span className="green">nti Cracker Campaign: </span>Our school organizes an Anti-cracker Rally annually before the festival of Diwali. Children and teachers from various schools attached to our school, regularly participate in this rally.</li>
                                   <li><span className="green">Water Conservation:</span>Waste water from the "Wash Basins" which does not contain any pollutants is recycled to water the lawns and to recharge the ground water. We have also constituted a "Water Brigade" comprising of school students to check leakage and wastage. This helps them to understand and realize the value of water conservation.</li>
                                   <li><span className="green">Earth Watch Week:</span> Week long activities are organized. Each day of the week is dedicated for a special cause. For example: <br/>
                                     Paper Conservation Day, Banner making, Skits, Water Conservation, Slogan &amp; Poster making, Electricity Conservation, Sticker Making, Anti-Pollution Day, Pollution check of vehicles and Assembly, which includes - Pledge by children, Street Plays, Speeches, Environment related songs to create awareness, Distribution of "Earth Day" badges.</li>
                                   <li><span className="green">Van Mahotsav Week and Greening Delhi Week:</span> Each day, various activities such as Eco Quiz, Symposium, Scroll Making, Card Making, Planting of Saplings in and outside the School Campus designing of pamphlets on MS Word, etc.</li>
                                   <li><span className="green">World Conservation Day:</span> On this day, we lay stress on conservation of Resources (electricity, water and paper) by organizing visits to landfills (Bhalsawa) and Thermal Power Plant (Indraprastha). Our students are also taken to observe the paper recycling plant where they actively participate in tearing the waste paper being recycled. They are also shown the compost pit in the school where the fallen leaves are decomposed.</li>
                                   <li><span className="green">Inter School Debate:</span> Every year, our school organizes a debate on environmental issues in which many prominent schools of Delhi participate. The topics are chosen with care on the present-day burning issues. For more information, please click Inter-School activities organized by the school (Section IV.e.iv).</li>
                                   <li><span className="green">Eco Tour:</span>Every year, an Eco Tour is organized for the members of Eco Club in order to sensitize them regarding biodiversity of the different regions, e.g. Churdhar in Himachal Pradesh, Jim Corbett Park in Nainital and Taru Mitra in Bihar, Mount Abu in Rajasthan.</li>
                                   <li><span className="green">Holding Exhibitions: </span>Art exhibitions of paper products (made out of waste) as held every year. This is in order to sensitize the children that waste paper can be recycled thus saving our trees.</li>
                                   <li><span className="green">School Nursery:</span> The school has a Nursery, which is maintained by Eco club members. Time and again, potted plants are gifted to our guests because we believe in preservation and not destruction.</li>
                                   <li><span className="green">No Smoking Zone:</span> We have declared our school as a "No Smoking Zone". All visitors and employees including DTC bus drivers and conductors, are not allowed to smoke within the school premises.</li>
                                   <li><span className="green">Anti Poly Bag Campaign: </span>Students and teachers have been sensitized not to bring poly bags to school. A rally is also organized to create awareness amongst the general public of the harmful disadvantages of poly bags.</li>
                                   <li><span className="green">Environment Quotient: </span>Environment quotient is raised by incorporating environment related questions in the school examination. Environment education as per CBSE guidelines is also being followed.</li>
                                   <li><span className="green">Disaster Management Education: </span>Mock drills are conducted to train the students and teachers in evacuation techniques for disasters, like fire, earthquake or any other calamity. For more information, please click Disaster Management (Section IV-D)</li>
                                   <li><span className="green">Community Involvement:</span> Pamphlets made by children with important messages on conservation, e.g. water, electricity, paper, etc. are sent periodically to parents. Saplings are distributed to children who take them home for planting, thus sensitizing the whole community on preserving trees. Inter school debates are organized. The topic for the Debate is chosen with care to create awareness in the young minds that sensitize the society at large. A newsletter (Dewdrops) is brought out to school to spread the environmental message to the community.</li>
                                 </ul>
                   
                   </div>
                   </div>
                   </div>
                    <div className="tab-pane fade" id="v-pills-lang" role="tabpanel" aria-labelledby="v-pills-lang-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>Languages</h3>
                             <p>The languages departments at PCS has for years experimented with multifarious and interesting activities and projects involving the students. All such activities are aimed to enrich the language and literary skills of the students. Some of the activities undertaken by the languages clubs are: </p>
                                 <ul className="ullist">
                                   <li>Training the students for inter-school competitions such as debates, plays, declamations, quiz, elocutions, skits and street plays.</li>
                                   <li>Introducing activity classes on literature and dramatics, which includes reading and playacting classics, enhancing knowledge of literary characters, poetry reading, book talk telling and creative writing.</li>
                                   <li>Organizing inter-school debates.</li>
                                   <li>Celebrating literary week in which various activities and competitions are held. Some of these activities and competitions include similes in a novel manner, bookmark making competition, fairy tale character parade (for primary section) book jacket designing, story illustration and role play on classics (for middle section) diction, act-a-word, poster making (for senior section).</li>
                                   <li>Screening of classic movies</li>
                                 </ul>
                   
                   </div>
                   </div>
                   </div>
                    <div className="tab-pane fade" id="v-pills-health" role="tabpanel" aria-labelledby="v-pills-health-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>Health Club</h3>
                    <p>After the child's family, the school is considered to be the next protective environment for children where they spend 60% of their waking hours. Therefore, to protect and address health problems and enhance the quality of life of school children, we began the Health Club. We monitor the personal hygiene of the students and encourage them to inculcate healthy eating habits. Through awareness programmes, special assemblies, rallies, workshops, skits, quiz and celebration of International Days such as World Health Day, World Aids Day, No Tobacco Day, etc. we impart holistic health.</p>
                   </div>
                   </div>
                   </div>
                    <div className="tab-pane fade" id="v-pills-heri" role="tabpanel" aria-labelledby="v-pills-heri-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>Heritage Club</h3>
                     <p>The most impressive feature of India's cultural history is the deep sense of continuity that is manifested in its more than thousand years of development. In India, the past has always been gathered up in the present and carried forward into the future. When anything new is accepted, the old is not wholly discarded but is preserved in a modified form. The main emphasis of the Club is to sensitize the students about their rich heritage.</p>
                   </div>
                   </div>
                   </div>
                    <div className="tab-pane fade" id="v-pills-child" role="tabpanel" aria-labelledby="v-pills-child-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>Child Rights Club</h3>
                                 <p><strong>"CHILD RIGHTS" -</strong> as the term suggests concern all children irrespective of Socio-economic class, caste, creed, colour, etc.</p>
                                 <p>We at Presentation Convent Sr. Sec. School, have taken the initiative of sensitizing the students on this important aspect of their life through the formation of <strong>"Child Rights Club"</strong> which comprise of students from classes VI, VII and VIII.</p>
                                 <p>Different activities ranging from essay writing, debates, elocution, drawing, poster making, participation in rallies and seminars take place.</p>
                                 <p>Visits to slums, various welfare home and institutions enable the students to become aware of the existing realities around us and to emerge as responsible citizens of society.</p>
                   
                   </div>
                   </div>
                   </div>
                    <div className="tab-pane fade" id="v-pills-human" role="tabpanel" aria-labelledby="v-pills-human-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>Human Rights Club</h3>
                   <p> Human Rights are international norms that help to protect people from severe political, legal and social abuses. Every human being is entitled to thirty Rights. These Rights are contained in a document called the Universal Declaration of Human Rights created in 1948 by the United Nations. The Human Rights Club aims at generating awareness about the Rights that are there to protect every person. The Rights help human beings to understand each other and live in peace.</p>
                   
                   </div>
                   </div>
                   </div>
                    <div className="tab-pane fade" id="v-pills-cadet" role="tabpanel" aria-labelledby="v-pills-cadet-tab"> 
                          <div className="row">
                             <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   <h3>National Cadet Corps (N.C.C.)</h3>
                   <p>The National Cadet Corps is a voluntary organization, which recruits cadets from schools who are given basic military training in small arms and parade. The motto of N.C.C. is "Unity and Discipline". We have a troop of 50 cadets (Junior Wing). They represent 2 Delhi Girls Battalion. N.C.C. aims at developing qualities of Character, Courage, Comradeship, Discipline, Leadership, Secular Outlook, Spirit of Adventure and the Ideals of Selfless Service amongst the youth who will always be available for the service of the nation.</p>
                   </div>
                   </div>
                   </div>
                   </div>
                   </div>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default Clubs
