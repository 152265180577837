import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import {getEvents} from'../Service/Api'
const HomeEvent = () => {
   const [data,setData] = useState([]);
   const [loading,setLoading] = useState(true);
   const [error,setError] = useState(null);

    useEffect(() => {
        const fetchData = async () =>{
               try{
                  const eventData = await getEvents();
                  setData(eventData) ;  
               }catch(error){
                setError(error);
               }finally{
                setLoading(false)
               }
        }
        fetchData();
    },[]);

    const emptyArray = [
       { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM.YY",time:"00:00",venue:"School Campus"},
       { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM.YY",time:"00:00",venue:"School Campus"},
       { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM.YY",time:"00:00",venue:"School Campus"}

    ]
    var settings = {  
       autoplay:true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,  
        arrows:false,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:1,  
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1 
              }
            }
          ]
      };
  return (
     <> 
     
     <div className="eventbg">
     <Slider {...settings}>
     {data && data.length > 0 ? (data.map((item,index) => (
         
      <div className="item" key={index}>
                          <div className="homenewsbox">
                               <div className="homenewsboximg">
                                  <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images}`} className="img-fluid" alt="Pcs Delhi"/>
                                   <div className="homenewsboxtitle"><p> {item.title} </p></div>
                               </div>
                               <div className="homenewsboxdesc">
                                  <p> {item.description} </p>
                                  <div className="homenewsboxbottom">
                                      <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cal.png" className="img-fluid"/> {item.date}  </span>
                                      <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/time.png" className="img-fluid"/> {item.time}</span>
                                  </div>
                                  <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/loc.png" className="img-fluid"/> {item.venue} </span>
                               </div>
                           </div>
                </div>  
  ))) : (emptyArray.map((item,index) => (
     <div className="item" key={index}>
                          <div className="homenewsbox">
                               <div className="homenewsboximg">
                                  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/events.jpg" className="img-fluid" alt="Pcs Delhi"/>
                                   <div className="homenewsboxtitle"><p> {item.title} </p></div>
                               </div>
                               <div className="homenewsboxdesc">
                                  <p> {item.description} </p>
                                  <div className="homenewsboxbottom">
                                      <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cal.png" className="img-fluid"/> {item.date} </span>
                                      <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/time.png" className="img-fluid"/> {item.time}</span>
                                  </div>
                                  <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/loc.png" className="img-fluid"/> {item.venue} </span>
                               </div>
                           </div>
                </div> 
  )))}
               {/*  
                                     <div className="item">
                         <div className="homenewsbox">
                              <div className="homenewsboximg">
                                 <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/event2.jpg" className="img-fluid" alt="Pcs Delhi"/>
                                  <div className="homenewsboxtitle"><p>Happy Father’s Day </p></div>
                              </div>
                              <div className="homenewsboxdesc">
                                  <p>Dads are most ordinary men
                                  turned by love into heroes, adventures, story-teller, and singing of song.</p>
                                  <div className="homenewsboxbottom">
                                  <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cal.png" className="img-fluid"/> 05 June 2024</span>
                                  <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/time.png" className="img-fluid"/> 08:00 AM </span>
                              </div>
                              <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/loc.png" className="img-fluid"/> School Campus</span>
                           </div>
                          </div>
                    </div>
                    <div className="item">
                         <div className="homenewsbox">
                              <div className="homenewsboximg">
                                 <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/event3.jpg" className="img-fluid" alt="Pcs Delhi"/>
                                  <div className="homenewsboxtitle"><p> Eid-al-Adha </p></div>
                              </div>
                              <div className="homenewsboxdesc">
                                 <p>My God flood your life with happiness on this occassion, your heart with love, your soul with spiritual.</p>
                                 <div className="homenewsboxbottom">
                                 <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cal.png" className="img-fluid"/> 05 June 2024</span>
                                 <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/time.png" className="img-fluid"/> 08:00 AM </span>
                             </div>
                             <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/loc.png" className="img-fluid"/> School Campus</span>
                          </div>
                          </div>
                    </div>
                    <div className="item">
                    <div className="homenewsbox">
                         <div className="homenewsboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/event1.png" className="img-fluid" alt="Pcs Delhi"/>
                             <div className="homenewsboxtitle"><p> World Environment Day </p></div>
                         </div>
                         <div className="homenewsboxdesc">
                            <p>We shall never understand the natural environment until we see it as a living organism</p>
                            <div className="homenewsboxbottom">
                                <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cal.png" className="img-fluid"/> 05 June 2024</span>
                                <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/time.png" className="img-fluid"/> 08:00 AM </span>
                            </div>
                            <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/loc.png" className="img-fluid"/> School Campus</span>
                         </div>
                     </div>
              </div>
              <div className="item">
                    <div className="homenewsbox">
                         <div className="homenewsboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/event2.jpg" className="img-fluid" alt="Pcs Delhi"/>
                             <div className="homenewsboxtitle"><p>Happy Father’s Day </p></div>
                         </div>
                         <div className="homenewsboxdesc">
                             <p>Dads are most ordinary men
                             turned by love into heroes, adventures, story-teller, and singing of song.</p>
                             <div className="homenewsboxbottom">
                             <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cal.png" className="img-fluid"/> 05 June 2024</span>
                             <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/time.png" className="img-fluid"/> 08:00 AM </span>
                         </div>
                         <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/loc.png" className="img-fluid"/> School Campus</span>
                      </div>
                     </div>
               </div>
               <div className="item">
                    <div className="homenewsbox">
                         <div className="homenewsboximg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/event3.jpg" className="img-fluid" alt="Pcs Delhi"/>
                             <div className="homenewsboxtitle"><p> Eid-al-Adha </p></div>
                         </div>
                         <div className="homenewsboxdesc">
                            <p>My God flood your life with happiness on this occassion, your heart with love, your soul with spiritual.</p>
                            <div className="homenewsboxbottom">
                            <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/cal.png" className="img-fluid"/> 05 June 2024</span>
                            <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/time.png" className="img-fluid"/> 08:00 AM </span>
                        </div>
                        <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/loc.png" className="img-fluid"/> School Campus</span>
                     </div>
                     </div>
              </div>
                */}
       </Slider>
     </div>
      
     </>
  )
}

export default HomeEvent