import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react'
const Sitemap = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Sitemap - Presentation Convent School Delhi';  
  }, []);
  return (
    <>
    <Header/>
    <div className="innerslide"> 
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>  
            <li>Sitemap</li>
        </ul>
   </div>
    <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1> Sitemap </h1>
                </div>
            </div>  
        <div className="row">
          
          <div className="col-md-3">
          <h3 class="subheading">  School </h3>
          <ul className='sitemapli'>
          <li><Link to="/SchoolInformation">School Information  </Link></li>
          <li><Link to="/TeacherMessage">Teacher's Message </Link></li>
          <li><Link to="/StaffInformation">Staff Information </Link></li>
          <li><Link to="/SchoolHistory">School History  </Link></li>
          <li><Link to="/SchoolPrincipals">Our Principals </Link></li>
          <li><Link to="/SchoolCabinetMembers">Cabinet Members </Link></li>
          <li><Link to="/SchoolMottoObjective">School Motto & Objective </Link></li>
          <li><Link to="/QualityManagement">Quality Management </Link></li>
          <li><Link to="/EnvironmentalManagement">Environmental Management </Link></li>
          <li><Link to="/PTAMember">PTA Members </Link></li>
          <li><Link to="/SMCMembers">SMC Members </Link></li>
          <li><Link to="/CBSEMandatory">CBSE Mandatory Disclosure </Link></li>
          </ul>  
          </div>
          
          <div className="col-md-3">
          <h3 class="subheading">   Admission  </h3>
          <ul className='sitemapli'>
          <li><Link to="/AdmissionDetails">Admission Details </Link></li>
          <li><Link to="/SchoolFees">School Fees </Link></li> 
          <li><Link to="/BusRoute">Bus Routes </Link></li>  
          <li><Link to="/Uniform">School Uniform </Link></li>
          <li><Link to="/SchoolPolicy">School Policy </Link></li>
          <li><Link to="/Infrastructure">Infrastructure  </Link></li> 
          <li><Link to="/ChildProtection">Child's Protection &  Safe Guarding </Link></li>
          </ul>  
          </div>
          
          <div className="col-md-3">
          <h3 class="subheading">  Academic </h3>  
          <ul className='sitemapli'>
          <li><Link to="/Rules">Rules And Regulations </Link></li>  
          <li><Link to="/CbseResultAnalysis">CBSE Result Analysis </Link></li>
          <li><Link to="/PrimarySchool">Primary School </Link></li>
          <li><Link to="/SeniorMiddleSchool">Senior Middle School </Link></li>
          <li><Link to="/StudySkills">Effective Study Skills </Link></li>
          <li><Link to="https://www.pcscampuscare.org/" target="_blank">Syllabus </Link></li>
          <li><Link to="https://www.pcscampuscare.org/" target="_blank">Holiday Homework </Link></li>  
          <li><Link to="/QuestionPapers">Question Papers </Link></li>         
          <li><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/pdf/booklist.pdf" target="_blank">Booklist </Link></li>        
          </ul> 
          </div>
          
          <div className="col-md-3">
          <h3 class="subheading">  Activities </h3>
          <ul className='sitemapli'>
          <li> <Link to="/Sports">Sports </Link></li>
          <li> <Link to="/Clubs">Clubs </Link></li>
          <li> <Link to="/InterSchoolCompetitions">Inter School Competitions </Link></li>
          <li> <Link to="/Cultural">Cultural </Link></li>
          <li> <Link to="/SocialActivities">Social Activities </Link></li>
          <li> <Link to="/SDG">SDG </Link></li>
          </ul>   
          </div>
          
          <div className="col-md-3">
          <h3 class="subheading"> Gallery </h3>
          <ul className='sitemapli'>
          <li><Link to="/PhotoGallery">Photo Gallery </Link></li>
          <li><Link to="/VideoGallery">Video Gallery </Link></li>
          <li><Link to="/Magazine">School Magazine  </Link></li>
          <li><Link to="/EHandbook">E-Handbook </Link></li>
          <li><Link to="/MediaGallery">Media Gallery </Link></li>
          </ul>   
          </div>
          
          <div className="col-md-3">
          <h3 class="subheading"> Alumnae </h3>
          <ul className='sitemapli'>
          <li><Link to="/OfficeBearers">Office Bearers </Link></li>
          <li><Link to="/AlumnaeEvents">Alumnae Events </Link></li>
          <li><Link to="https://www.pcscampuscare.org/" target="_blank">Online Registration </Link></li>
          </ul>   
          </div>
          
          <div className="col-md-3">
          <h3 class="subheading">  Quick Links </h3>
          <ul className='sitemapli'>
          <li><Link to="/Tc">TC Issued </Link></li>
          <li><Link to="/ParentGuidline">Portal (Help?) </Link></li>
          <li><Link to="/ErpSoftware">ERP Software </Link></li>
          </ul>
          </div>
          </div>
        </div>
      </div> 
      
      <Footer/>
    </>
  );
};

export default Sitemap;
