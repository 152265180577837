import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const SchoolPrincipals = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'School Principals - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>School Principals</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Principals</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-2021-present.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Regi Joseph  </h4>
                      <h5>2021-Present </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-2016-2021.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Jyoti</h4>
                      <h5>2016 - 2021</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-2009-16.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Regi Joseph  </h4>
                      <h5>2009-2016 </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-2008-2009.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Elsie Thomas </h4>
                      <h5>2008-2009</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-2001-2008.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Rosamma Thomas </h4>
                      <h5>2001-2008</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1998-2001.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Phyllis Pullickal </h4>
                      <h5>1998 - 2001</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1993-98.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Anice George </h4>
                      <h5>1993 - 1998</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1990-93.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Gemma Pires </h4>
                      <h5>1990 - 1993</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1986-90.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Roselima  </h4>
                      <h5>1986 - 1990</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1974-86.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Bonaventure Morgan </h4>
                      <h5>1974 - 1986</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1968-74.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Veronica  </h4>
                      <h5>1968 - 1974</h5>
                    </div>
                  </div>
                </div>
        
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1963-68.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Berchmans </h4>
                      <h5>1963 - 1968</h5>
                    </div>
                  </div>
                </div>
        
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1957-63.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Baptist </h4>
                      <h5>1957 - 1963</h5>
                    </div>
                  </div>
                </div>
        
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1951-57.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Phelim  </h4>
                      <h5>1951 - 1957</h5>
                    </div>
                  </div>
                </div>
        
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1945-51.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Mary Sacred Heart  </h4>
                      <h5>1945 - 1951</h5>
                    </div>
                  </div>
                </div>
        
                <div className="col-xxl-3 col-lg-4 col-md-4">
                  <div className="management">
                    <img alt="PCS DELHI" src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/pri-1939-45.jpg" className="img-fluid"/>
                    <div className="management-detils">
                      <h4>Sr. Gabriel Monks  </h4>
                      <h5>1939 - 1945</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SchoolPrincipals
