import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const SchoolFees = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'School Fees - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Admission  </li>
                <li>School Fees</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Fees</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <ul className="innerlist">
                      <li>School fees is charged quarterly for <strong>April-June, July-September, October-December and January-March</strong>. It is payable within the first fifteen days from the date the fee slip is sent to the class, which is usually the first working day of each quarter.</li>
                   <li>School fees is accepted by way of cheque only.</li>
                   <li>The cheque should be payable to Presentation Convent Sr. Sec. School. Write on the back of the cheque: Name of the student, Fee Slip No., Class &amp; Section.</li>
                   <li>Outstation cheques are not accepted.</li>
                   <li>During this period, a "Drop Box" will be kept within the school premises. Cheques must be dropped in the "Drop Box" only.</li>
                   <li>After the last date, as indicated above, the fee will be accepted only at Union Bank of India, S.B. Sarafa Market, during banking hours. A late fee of Rs. 100/- will be charged for first 15 days, an additional amount of Rs. 200/- per month will be charged till the fee has been paid.</li>
                   <li>If the cheque is returned/dishonored on account of insufficient funds/post dated/any other, the payment of the same will be accepted in School only by way of Pay Order along with a fine Rs. 500/-</li>
                   <li>A fine of Rs. 25/- will be charged for issuing a duplicate fee slip.</li>
                   <li>Students should clear all dues before the examinations. Otherwise they will not be allowed to sit for the examination.</li>
                   <li>Parents have the option to pay the fee in advance for the whole year. A rebate of Rs. 500/- will be given to those who pay the full fee in April.</li>
                      </ul>
                          <br />
                              <div className="table-responsive maintable">
                         
                      <table className="table">
                          <thead>            
                              <tr>
                                  <th>Class</th>
                                  <th>Tution Fees (Monthly)</th>
                                  <th>Annual Fees</th> 
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>KG</td>
                                  <td>4700</td>
                                  <td>8400</td> 
                              </tr>
                              <tr>
                                  <td>I-II</td>
                                  <td>4700</td>
                                  <td>8400</td> 
                              </tr>
                              <tr>
                                  <td>III-V</td>
                                  <td>4525</td>
                                  <td>8400</td> 
                              </tr>
                              <tr>
                                  <td>VI-X</td>
                                  <td>5090</td>
                                  <td>10800</td> 
                              </tr>
                              <tr>
                                  <td>XI-XII</td>
                                  <td>5370</td>
                                  <td>10200</td>
                              </tr>
                          </tbody>
                      </table>
                      </div>
                      
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SchoolFees
