import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react'
const ChildProtection = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Child Protection & Safe Guarding - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Admission </li>
                <li>Child Protection & Safe Guarding</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Child Protection & Safe Guarding</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12"> 
                            <h5>Policy Statement</h5>
                            <p>We value and encourage the participation of children in any activity that enhances their spiritual, physical, emotional, intellectual and social development. We are committed to safeguarding the dignity and rights of all children. We do all in our power to create a safe environment for children. We co-operate fully with the National Board for safeguarding children.</p>
                            <h5>Therefore, we:-</h5>
                            <ul className="list">
                                <li>Foster best practice</li>
                                <li>Ensure accountability through establishing effective structures</li>
                                <li>Support personnel in safeguarding children</li>
                                <li>Respond effectively to allegations and suspicious of abuse</li>
                                <li>Report allegations where there is reasonable cause for concern and co-operate with civil authorities</li>
                                <li>Take just and appropriate action in relation to Presentation personnel who have abused</li>
                                <li>Take effective measures against future risk of abuse</li>
                                <li>Promote healing and reconciliation </li>
                            </ul>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default ChildProtection
