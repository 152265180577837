import React, { useState, useEffect } from 'react';
import { getTopper } from '../Service/Api';
import Header from '../Component/Header';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';

const TopperX = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("");
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    // Set the page title
    useEffect(() => {
        document.title = 'Topper XII - Presentation Convent School Delhi';  
    }, []);

    // Initialize year ranges and data
    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getTopper();
            const classXData = galleryData.filter(item => item.class === 'XII');

            // Extract unique years
            const uniqueYears = Array.from(new Set(classXData.map(item => item.sessionYear)));
            setData(classXData);  
            setYearRanges(uniqueYears);

            // Default to the most recent year if available
            const currentYear = new Date().getFullYear();
            const mostRecentYear = uniqueYears.sort().reverse()[0];
            setSelectedYear(mostRecentYear || "");
        };
        fetchData();
    }, []);

    // Update filtered data based on selected year and search query
    useEffect(() => {
        const filterData = () => {
            let filtered = data;

            if (selectedYear && selectedYear !== "All") {
                filtered = filtered.filter(item => item.sessionYear === selectedYear);
            }

            if (searchQuery) {
                filtered = filtered.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
            }

            // Sort data by division in descending order
            filtered.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));
            return filtered;
        };

        const currentFilteredData = filterData();
        
        if (currentFilteredData.length === 0 && selectedYear !== "All") {
            // If no data is available for the selected year, show data from the previous year
            const availableYears = yearRanges.filter(year => year < selectedYear);
            const fallbackYear = availableYears.sort().reverse()[0];
            if (fallbackYear) {
                setSelectedYear(fallbackYear);
                const fallbackData = data.filter(item => item.sessionYear === fallbackYear);
                setFilteredData(fallbackData);
            } else {
                setFilteredData([]);
            }
        } else {
            setFilteredData(currentFilteredData);
        }
    }, [selectedYear, searchQuery, data, yearRanges]);

    const emptyArray = [
        { name: "Student Name", division: "%" },
        { name: "Student Name", division: "%" },
        { name: "Student Name", division: "%" },
        { name: "Student Name", division: "%" }
    ];

    return (
        <>
            <Header />
            <div className="innerslide"> 
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Topper XII</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Topper XII</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="chooseyear">
                                <select id="chooseddlYear"
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection"
                                >
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="clr"></div>
                            <div className="row">
                                {filteredData.length > 0 ? (
                                    filteredData.map((item, index) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                                            <div className="topperdiv">
                                                <div className="topperimages">
                                                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} className="img-fluid" alt={item?.name} />
                                                </div>
                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn" alt="Topper Icon" />
                                                <div className="topperdesc">
                                                    <p className="per">{item?.division}</p>
                                                    <p className="name"> {item?.name} <span> </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    emptyArray.map((item, index) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                                            <div className="topperdiv">
                                                <div className="topperimages">
                                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/defaultImage.jpg" className="img-fluid" alt="Default" />
                                                </div>
                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/PCSD/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn" alt="Topper Icon" />
                                                <div className="topperdesc">
                                                    <p className="per">{item.division}</p>
                                                    <p className="name"> {item.name} <span> </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TopperX;
